import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import bannerhome1 from './data_banner';
import bannerhome2 from '../home02/data_banner';

class Slider extends Component {

    render() { return null;
    }
}

export default withRouter (Slider);