import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServicesBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardbox: [
                {
                    id: 1,
                    colspan: [
                        {
                            idx: 1,
                            icon: './assets/icon/icon_Style and Design.svg',
                            title: 'Style and Design',
                            text: 'We develop and understand project expectations and then manage those needs with a design team. Innovation should happen throughout a project.'
                        }
                    ],
                },
                {
                    id: 2,
                    colspan: [
                        {
                            idx: 2,
                            icon: './assets/icon/icon_Carpentry Expertise.svg',
                            title: 'Carpentry Expertise',
                            text: 'Autora Construction Services provides the right resources and expertise to evaluate concepts through our industry leading Preconstruction Services team.',
                        }
                    ]
                },
                {
                    id: 3,
                    colspan: [
                        {
                            idx: 3,
                            icon: './assets/icon/icon_Family Values.svg',
                            title: 'Family Values',
                            text: 'Our in-house cladding teams supply and install a diverse range of solutions to new building and building envelope upgrade projects.',
                        }
                    ],
                    colleft: []

                },            ]
        }
    }
    render() {
        return (
	






            <div className="row" style={{paddingTop:'50px'}}>

                {
                    this.state.cardbox.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                            {
                                data.colspan.map( spandata =>(
                                    <div className="themesflat-icon-box icon-left accent-color style-2 clearfix" key={spandata.idx}>
                                        <div className="icon-wrap">
                                            <img src={spandata.icon} width="90px"  style={{paddingRight: "32%"}}/>
                                        </div>
                                        <div className="text-wrap">
                                            <h5 className="heading"><Link to="#">{spandata.title}</Link></h5>
                                            <p className="sub-heading">{spandata.text}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
		<br/>
            </div>

        );
    }
}

export default ServicesBox;