import React, { Component,useEffect } from 'react'
import {Link} from 'react-router-dom'
import {Slider, TitleHeading, IconBox, About, Service, Project, ServicesBox, Testimonial, Overview, Faq, Partner} from '../layouts/home01/index'
import {Header, Footer, TopBar, BottomBar} from '../layouts/general/index'
import {Helmet} from "react-helmet";


class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    logoweb: "assets/img/logo-small.png",
                    names: "Home",
                }
            ],
            headingchoose: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'Your Personalized Workspace Retreat',
                    text: 'The concept of working from home has taken on a whole new meaning, and a garden office offers the ideal solution. It\'s more than just a place to work - it\'s a tranquil oasis that allows you to separate your professional life from your personal space while enjoying the beauty of the outdoors.',
                    classtext: 'sub-heading',
                }
            ],
            headingservice: [
                {
                    id: 1,
                    classnames: 'heading',
                    title: 'BEST SERVICES 1',
                    text: 'Construction Services offers a full range of construction services from initial design to project completion.',
                    classtext: 'sub-heading',
                }
            ],
            headingproject: [
                {
                    id: 1,
                    classnames: 'heading text-white',
                    title: 'FEATURED PROJECT',
                    classtext: 'sub-heading',
                }
            ],
            servicebox: [
                {
                    id: 1,
                    classnames: 'heading font-size-30',
                    title: 'What Sets Us Apart',
                    classtext: 'sub-heading font-weight-400',
                    text: 'Every garden is unique, and so is every client. Our design service is tailored to your specific needs and preferences. We start by listening carefully to your requirements, understanding your vision, and considering the layout of your outdoor space. Whether you need a dedicated home office, a creative studio, or a quiet reading nook, we design and build a garden office that perfectly blends with your surroundings.'
                }

            ]
        }


    }
    
  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    this.instance.appendChild(s);
const cookieContainer = document.querySelector(".cookie-container");
const agreeBtn = document.querySelector(".agree button");

setTimeout(() => {
  cookieContainer.classList.remove("hide");
}, 1000);

agreeBtn.addEventListener("click", () => {
  cookieContainer.classList.add("hide");
});

  }

    render() {
        return (
            <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
<div ref={el => (this.instance = el)} />
 <div id="wrapper" className="animsition">
                    <div id="page" className="clearfix">
                        <div id="site-header-wrap">
                            <TopBar />
                            {
                                this.state.headers.map((data,idx) =>(
                                    <Header data={data} key={idx}/>
                                ))
                            }
                        </div>
                        <div id="main-content" className="site-main clearfix">
                            <div id="content-wrap">
                                <div id="site-content" className="site-content clearfix">
                                    <div id="inner-content" className="inner-content-wrap">
                                        <div className="page-content">
                                            <div className="row-iconbox " style={{margin:'0 auto',paddingBottom:'35px'}}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={60} data-smobile={60} />
                                                            {
                                                                this.state.headingchoose.map(data => (
                                                                    <TitleHeading data={data} key={data.id}/>
                                                                ))
                                                            }
                                                            <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                                                        </div>
                                                    </div>
                                                    
                                                    <IconBox />
                                                </div>
                                            </div>
                                            <About />
                                            <div className="row-iconbox">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={60} data-smobile={60} />
                                                            {
                                                                this.state.servicebox.map(data => (
                                                                    <TitleHeading data={data} key={data.id}/>
                                                                ))
                                                            }
                                                            <div className="" data-desktop={57} data-mobile={35} data-smobile={35} />                    
<ServicesBox />
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="themesflat-spacer clearfix" data-desktop={72} data-mobile={60} data-smobile={60} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                                                        <div className="row-tabs">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="themesflat-spacer clearfix" data-desktop={61} data-mobile={40} data-smobile={40} />
                                                        </div>
                                                    </div>
                                                    <div className="row">


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                        {
                            this.state.headers.map((data,idx) =>(
                                <BottomBar data={data} key={idx}/>
                            ))
                        }
                    </div>
                </div>
<div class="cookie-container hide">
	<p class="cookie-text">
This notification empowers you to make an informed decision regarding the use of cookies while navigating our website. By clicking 'Accept,' you affirmatively consent to our cookie practices as detailed in our Cookie Policy. Furthermore, you have the option to delve deeper into our policy to gain a comprehensive understanding of how cookies operate and how to tailor them to your preferences by clicking 'Settings.'
We value your privacy and are committed to providing you with a seamless and personalized browsing experience. If you have any questions or concerns regarding our Cookie Consent Notification or our Cookie Policy, please do not hesitate to contact us.
<a href="#"> Privacy Policy.</a>
	</p>
	<div class="agree">
	<button>Agree</button>	<button>Dismiss</button>
		</div>
</div>

            </div>
        );
    }
}

export default Home01;