import React, { Component } from 'react';

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: [
                {
                    id: 1,
                    text: 'Quality is at the heart of everything we do. We don\'t just meet expectations - we surpass them. Our commitment to using top-notch components and getting the job done right ensures our woodwork is of the highest standard.',
                    item: [
                    ]
                },
                {
                    id: 2,
                    text: 'Trust is the bedrock of any strong relationship. We operate with integrity in all our dealings. From transparent pricing to open and honest communication, you can rely on us to be upfront and dependable every step of the way.',
                    item: [
                    ]
                },
                {
                    id: 3,
                    text: 'Your satisfaction is our ultimate goal. We\'re not satisfied until you\'re delighted with the final result. We listen to your vision, work closely by your side, and always go the extra mile.',
                    item: [                    ]
                },
                {
                    id: 4,
                    text: 'While we deeply respect tradition, we\'re also champions of innovation. We seamlessly blend time-honored techniques with modern creativity, producing woodwork that\'s not only stunning but also unlike anything else that can be found on the market.',
                    item: [
                    ]
                }

            ]
        }
    }
    
    render() {
        return (
            <div className="col-md-12">
                <div className="themesflat-content-box clearfix" data-margin="0 18px 0 0" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-headings style-1 clearfix">
                        <h2 className="heading">Our Core Values</h2>
                        <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop={38} data-mobile={35} data-smobile={35} />
                    <div className="themesflat-tabs style-1 clearfix">
                        <ul className="tab-title clearfix">
                            <li className="item-title active">
                                <span className="inner">Quality</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Integrity</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Customer Satisfaction</span>
                            </li>
                            <li className="item-title">
                                <span className="inner">Innovation</span>
                            </li>
                        </ul>
                        <div className="tab-content-wrap clearfix" style={{paddingBottom: '50px'}}> 
                            {
                                this.state.tab.map(data => (
                                    <div className="tab-content" key={data.id}>
                                        <div className="item-content">
                                            <p>{data.text}</p>
                                            {
                                                data.item.map(index => (
                                                    <div className="themesflat-list has-icon style-1 icon-left ofset-padding-32 clearfix" key={index.idx}>
                                                        <div className="inner">
                                                            <span className="item">
                                                                <span className="icon"><i className="fa fa-check-square" /></span>
                                                                <span className="text">{index.content}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>{/* /.tab-content-wrap */}
                    </div>{/* /.themesflat-tabs */}
                </div>{/* /.themesflat-content-box */}
            </div>
        );
    }
}

export default Overview;