import React, { Component } from 'react';

class Counter extends Component {
    render() {
        return (
            <div className="row-counter parallax parallax-4 parallax-overlay">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-16">
                            <div className="themesflat-spacer clearfix" data-desktop={92} data-mobile={60} data-smobile={60} />
                        </div>
                    </div>
                    <div className="themesflat-row gutter-20 separator light">
                        <div className="sub-heading">
                            <div className="themesflat-content-box clearfix" data-margin="0 0 0 0" data-mobilemargin="0 0 0 0">
                                <div className="themesflat-counter style-1 align-center clearfix">
                                    <div className="counter-item">
                                        <div className="inner">
                                            <div className="text-wrap" style={{color:'white',    maxwidth: '590px'}}>
                                                <h2 className="" style={{color:'white'}}>Your Vision, Our Priority</h2>

<p class="sub-heading" style={{maxWidth:'590px',margin:'0 auto'}}>We understand that your home is a reflection of your personality and lifestyle. It's where you create cherished memories, and every piece of woodwork should mirror your unique style. Entrusting us with your vision is an honor we take seriously, and we're proud to give your space the care and attention it deserves.
<br/><br/>Thank you for considering Kiwi Kustom Limited for all your carpentry needs. We look forward to working with you soon. Together, we'll capture the essence of who you are and what you love.
                                            </p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={60} data-mobile={35} data-smobile={35} />
                        </div>
                                            </div>
                </div>
                <div className="bg-parallax-overlay overlay-black style2 heading margin-top-18" />
            </div>
        );
    }
}

export default Counter;