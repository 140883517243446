import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inner: [
                {
                    id: 1,
                    icon: 'fa fa-check-square',
		    kazkas: 'Carpentry:',
                    text: 'Our skilled carpenters can tackle a wide range of projects, from crafting custom furniture to building intricate wooden structures. No task is too big or too small for our team.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check-square',
		    kazkas: 'Joinery:',
                    text: 'If you need precise joinery work for your home or office, look no further. We specialize in creating seamless and functional joinery solutions that elevate the appeal of your space.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check-square',
		    kazkas: 'Care and Repair:',
                    text: 'We believe that quality pieces should be built to last. That\'s why we offer comprehensive care and repair services to ensure that your investment remains in excellent condition for years to come.'
                },
                {
                    id: 4,
                    icon: 'fa fa-check-square',
		    kazkas: 'Other services:',
                    text: '24hr emergency calls, shopfitting, roof repairs and many more..'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row-about">
                <div className="container-fluid">
                    <div className="row equalize sm-equalize-auto">
                        <div className="col-md-7 half-background style-1">
			<div><img src="./assets/img/kiwikustom.png" height="100%"/></div>
			</div>
                        <div className="col-md-5 bg-light-grey">
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={60} />
                            <div className="themesflat-content-box clearfix" data-margin="0 25% 0 4.5%" data-mobilemargin="0 0 0 4.5%">
                                <div className="themesflat-headings style-1 clearfix">
                                    <h2 className="heading">Welcome to KiwiKustom Limited </h2>
                                    <div className="sep has-width w80 accent-bg margin-top-11 clearfix" />  
					<br/> 
				    <h4 style={{color:'grey'}}>Your Premier Source for Expert Carpentry Services</h4>                                            
                                    <p className="sub-heading margin-top-10" style={{fontSize:'15px',lineHeight: '1.5'}}>With a passion for design, a love for carpentry, and a commitment to exceptional craftsmanship, we aim to bring your vision to life. At KiwiKustom Limited, we take pride in being a family-run business based in the heart of the United Kingdom.</p>
                                </div>
				<br/>
				<h4 className="heading margin-top-10" style={{color:'grey'}}>Our Services</h4>
                                <div className="themesflat-spacer clearfix" data-desktop={26} data-mobile={35} data-smobile={35} />
                                <div className="content-list">
                                    <div className="themesflat-list has-icon style-1 icon-left clearfix">
                                        <div className="inner">
                                            {
                                                this.state.inner.map(data => (
                                                    <span className="item" style={{}} key={data.id}>
                                                        <span className=""><i style={{color:'green'}} className={data.icon} /> <b style={{color:'black',fontSize:'15px'}}>{data.kazkas}</b></span>
                                                        <span className="text"> {data.text}</span>
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="themesflat-spacer clearfix" data-desktop={42} data-mobile={35} data-smobile={35} />
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={60} />
	<br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;