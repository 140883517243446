import React, { Component } from 'react';


class TopBar extends Component {
    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap">
                        <div className="top-bar-content">
                            <div className="inner">
                                <a href="whatsapp:contact=447851283981"><span className="phone content">+447851283981</span></a>
                                <span className="time content">Mon-Sun: 24hours</span>
                            </div>                            
                        </div>
                        <div className="top-bar-socials">
                            <div className="inner">
                                <span className="text">Follow us:</span>
                                <span className="icons">
                                    <a href="https://www.facebook.com/profile.php?id=61551057537674"><i className="fa fa-facebook" /></a>
                                    <a href="https://www.instagram.com/kiwikustom.limited/"><i className="fa fa-instagram" /></a>
                               <a href="https://www.pinterest.co.uk/kiwikustomlimited/"><i className="fa fa-pinterest" /></a>

 </span>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

export default TopBar;