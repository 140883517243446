import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="clearfix">
                <div id="footer-widgets" className="container"><div className="themesflat-row gutter-50">
                     </div>

                    <div className="themesflat-row gutter-50">
                        <div className="col span_1_of_4">
                            <div className="widget widget_text">
                                <div className="textwidget">
                                    <h3 style={{textAlign:'left',color:'white'}}>
                                         Our Mission
                                    </h3>
                                    <p className="margin-bottom-15">Our mission is simple yet profound: to design and create spaces that are not only beautiful, but also functional. We believe that where you live and work should inspire creativity and productivity, and that's precisely what we aim to deliver.</p>
                                    <ul>
                                        <li>
                                            <div className="inner">
                                                <span className="fa fa-phone" />
                                                <span className="text">CALL US : (+44) 78 512 83981</span>
                                            </div>
                                        </li>
                                        <li className="margin-top-7">
                                            <div className="inner">
                                                <span className=" font-size-14 fa fa-envelope" />
                                                <span className="text"><a class="linkas" href="mailto:info@kiwikustom.uk">Get In Touch (Click Here)</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>{/* /.widget_text */}
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                        </div>{/* /.col */}
                        <div className="col span_1_of_4">
                            <div className=" widget_text" style={{fontWeight: '500',fontsize: '15px',color:'#999',fontFamily: 'Montserrat, sans-serif'}}>
                                <div className="textwidget">
                                    <h3 style={{textAlign:'left',color:'white'}}>
                                         Get in Touch
                                    </h3>
                                    <p className="margin-bottom-15">Ready to give your space a total transformation? We're just a quick phone call away.

Whether you have a clear vision or need some creative guidance, our team is here to turn your ideas into reality. We handle everything from design to construction, ensuring a hassle-free experience from start to finish.

Contact us today to discuss your project, request a quote, or schedule a consultation.</p>
                                                                    </div>
                            </div>{/* /.widget_text */}
                            <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                        </div>{/* /.col */}


                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;